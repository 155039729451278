<div fxFlex="100" class="wrapper-dashboard">
  <div class="updateData">
    <div class="data-btn">
      <button (click)="RefreshData()" class="lenexa-btnn">Update</button>
      <p>Last Check:{{updatedTime}}</p>
    </div>
    <div class="add-remove-btn">
      <button (click)="PairingDevice()" class="lenexa-btnn">+Add device</button>
      <button (click)="unpairPatients()" class="lenexa-unpairbtn">-Unpair device</button>
    </div>
  </div>
  <div *ngIf="(data.length || filtersApplied || dataLoader) else noDataTemplate" fxLayout="row" class="pad-left-30">
    <div *ngIf="dataLoader" class="data-spinner">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div *ngIf="!dataLoader" class="patient-list" fxFlex="96%" fxFlex.xs="100%">
      <table>
        <thead>
          <tr>
            <th style="border: none">
              <div class="table-visualize">
                <div
                  class="visualize">
                  <button class="visual-btn" (click)="displayGraph()">
                    <p>Visualise</p>
                  </button>
                </div>
                <div class="close-btn">
                  <button class="closeall" (click)="closeAllgraphs()">
                    <p>Close All</p>
                  </button>
                </div>
              </div>
            </th>

            <th style="width: 2%;">
              <div class="table-row">
                <p>Hub ID</p>
                <div class="img-settle"><img class="dropdown-img"
                    [class.asc]="hubIdHighlighted === 'asc'" (click)="sortingData('hub_id','asc')"
                    src="assets\images\Polygon 1.png" alt="Up Arrow">
                  <img class="dropdown-img" [class.desc]="hubIdHighlighted === 'desc'"
                    (click)="sortingData('hub_id','desc')" src="assets\images\Polygon 2.png" alt="Down Arrow">
                </div>
              </div>
            </th>


            <th style="width: 2%;">
              <div class="table-row">
                <p>Ward</p>
                <div class="img-settle">
                  <img class="dropdown-img" [class.asc]="wardHighlighted === 'asc'"
                    (click)="sortingData('ward','asc')" src="assets\images\Polygon 1.png" alt="Up Arrow">
                  <img class="dropdown-img" [class.desc]="wardHighlighted === 'desc'"
                    (click)="sortingData('ward','desc')" src="assets\images\Polygon 2.png" alt="Down Arrow">
                </div>
              </div>
            </th>


            <th style="width: 2%;">
              <div class="table-row">
                <p>Bed</p>
                <div class="img-settle"><img class="dropdown-img"
                    [class.asc]="BedHighlighted === 'asc'" (click)="sortingData('bed','asc')"
                    src="assets\images\Polygon 1.png" alt="Up Arrow">
                  <img class="dropdown-img" [class.desc]="BedHighlighted === 'desc'" (click)="sortingData('bed','desc')"
                    src="assets\images\Polygon 2.png" alt="Down Arrow">
                </div>
              </div>
            </th>


            <th style="width: 3%;" class="filter-container"><img class="filter-icon"
                (click)="dataStatus_dropdown('datastatus')" src="assets\images\new-filter.jpg">
              <p>Data Status({{ selectedDataCheckboxes }})</p>
              <div class="img-settle1" style="margin-top: -34px;"><img class="dropdown-img"
                  style="margin-top: -2px;" [class.asc]="dataHighlighted === 'asc'"
                  (click)="sortingData('datastatus','asc')" src="assets\images\Polygon 1.png" alt="Up Arrow"><img
                  class="dropdown-img" [class.desc]="dataHighlighted === 'desc'"
                  (click)="sortingData('datastatus','desc')" src="assets\images\Polygon 2.png" alt="Down Arrow"></div>

              <div class="drop-toggle" *ngIf="isDropdownOpen && activeDropdown === 'datastatus'">
                <div class="filter-options">
                  <p>Data Status Filter</p>
                  <hr />
                  <label *ngFor="let item of dataStatus">
                    <input type="checkbox" [(ngModel)]="item.selected"
                      (change)="handleCheckboxSelection('datastatus', item)"> {{ item.name }}</label>
                </div>
                <div class="filter-btns">
                  <button class="submit-btn"
                    (click)="filterData('datastatus','selectedDataStatusCheckboxes')">Confirm</button>
                  <button (click)="closeFilter()" class="cancel-btn">Cancel</button>
                </div>
              </div>
            </th>

            <th style="width: 6%;" class="filter-container"><img class="filter-icon1"
                (click)="dataStatus_dropdown('riskstatus')" src="assets\images\new-filter.jpg">
              <p>Risk Status({{ selectedRiskCheckboxes }})</p>
              <div class="img-settle1" style="margin-top: -34px;"><img class="dropdown-img"
                  style="margin-top: -2px;" [class.asc]="riskHighlighted === 'asc'"
                  (click)="sortingData('risk_type','asc')" src="assets\images\Polygon 1.png" alt="Up Arrow"><img
                  class="dropdown-img" [class.desc]="riskHighlighted === 'desc'"
                  (click)="sortingData('risk_type','desc')" src="assets\images\Polygon 2.png" alt="Down Arrow"></div>
              <div class="drop-toggle1" *ngIf="isDropdownOpen && activeDropdown === 'riskstatus'">
                <div class="filter-options1">
                  <p>Risk Status Filter</p>
                  <hr />
                  <label *ngFor="let item of riskStatus">
                    <input type="checkbox" [(ngModel)]="item.selected"
                      (change)="handleCheckboxSelection('risk_type', item)"> {{ item.name }}</label>
                </div>
                <div class="filter-btns1">
                  <button class="submit-btn1"
                    (click)="filterData('risk_type','selectedRiskStatusCheckboxes')">Confirm</button>
                  <button (click)="closeFilter()" class="cancel-btn1">Cancel</button>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody *ngFor="let item of data;let i=index">
          <tr>
            <td style="border: none;">
              <input style="height: 28px;border: 1px solid black;width: 36px;" type="checkbox"
                [(ngModel)]="item.selected" (change)="onCheckboxChange(item)">
            </td>
            <td>{{item.hub_id}}</td>
            <td>{{item.ward}}</td>
            <td>{{item.bed}}</td>
            <td>{{item.datastatus}}</td>
            <td [ngClass]="getColor(item.risk_type)">{{item.risk_status}}</td>
          </tr>
          <tr *ngIf="displayTimeline && item.isSelected">
            <td colspan="6" style="border: 0px;">
              <app-timeline [timelineId]="item.patient_number" [dataToDisplay]="item.timelineGraphData"
                [graphId]="item.timeGraphId"></app-timeline>
            </td>
          </tr>
        </tbody>
      </table>
      <div fxLayout="row" class="page-count">
        <div class="page-container">Page:<span *ngFor="let page of pagesArray;let last = last" (click)="goToPage(page)"
            (mouseover)="handlePageHover(true)" (mouseout)="handlePageHover(false)"
            [class.active]="currentPage === page" [class.hovered]="isHovered">
            {{ page }}{{ last ? '' : ',' }}</span></div>
        <img class="pagecontainer-arrows"
          src="assets\images\Polygon 4.png" (click)="previousPage();handleArrowClick()"
          [style.opacity]="currentPage === 1 ? 0.5 : 1" [attr.disabled]="currentPage === 1 ? true : null"
          [class.highlight]="isArrowClicked" />
        <img class="pagecontainer-arrows"
          src="assets\images\Polygon 3.png" (click)="nextPage(); handleArrowClick()"
          [style.opacity]="currentPage === totalPages ? 0.5 : 1"
          [attr.disabled]="currentPage === totalPages ? true : null" [class.highlight]="currentPage === page"
          [class.highlight]="isArrowClicked" />
      </div>
    </div>
  </div>
</div>
<ng-template #noDataTemplate>
  <div class="display-center">
    <h3>No active bed, please click the "+add device" button to add a bed.</h3>
  </div>
</ng-template>