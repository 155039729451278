<div class="top-nav">
  <img id="logo" src="assets\images\lenexa-logo.png">
  <div class="header-align-right">
    <div *ngIf="orgImg"><img id="logo" class="userImg1" [src]="orgImg" *ngIf="hasRoute('dashboard')"
        (click)="navigateToOrganization()">
    </div>
    <div class="normal-user">
      <div *ngIf="userImg">
        <img id="logo" class="user" [src]="userImg" *ngIf="hasRoute('dashboard') || hasRoute('connector')"
          (click)="user_role()">
      </div>
      <div class="version" *ngIf = "hasRoute('dashboard')">{{presentVersionBuild}}</div>
      <img id="logo" class="pointer" src="assets\images\help_button.svg"
        *ngIf="hasRoute('dashboard') || hasRoute('connector')" (click)="fileOpen()">
      <img id="logo" class="pointer" src="assets\images\logout.svg"
        *ngIf="hasRoute('dashboard') || hasRoute('organization')" (click)=" navigateToConnection()">
      <img id="logo" class="pointer" src="assets\images\hom-btn.png" *ngIf="hasRoute('connector')"
        (click)="navigateTODashboard()">
      <img id="logo" class="pointer" src="assets\images\dashboard-img.png" *ngIf="hasRoute('admin')"
        (click)="navigateDashboard()">
    </div>
  </div>
</div>