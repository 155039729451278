import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, BaseService, PatientService } from '../../services';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { PatientDataboxListComponent } from '../patient-databox-list/patient-databox-list.component';
import { HttpParams } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';
import { IdleStateService } from 'src/app/services/idle-state.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(MatSelect) matSelect: MatSelect;
  navigationUrl: string;
  updatedTime: any;
  isDropdownOpen: boolean = false;
  activeDropdown: string = '';
  @Input() dataToDisplay: any = [];
  displayTimeline: boolean = false;
  checkboxValue: boolean = false;
  token: any;
  hubidselection: any = [];
  private dataSubscription: Subscription;
  dataStatus: any = [
    { name: 'Sending Data', selected: false },
    { name: 'No Data', selected: false },
  ]
  selectedDataCheckboxes: number = 0;
  selectedRiskCheckboxes: number = 0;
  riskStatus: any = [
    { name: 'No Data', selected: false },
    { name: 'Out Of Bed', selected: false },
    { name: 'No Risk', selected: false },
    { name: 'Mid Risk', selected: false },
    { name: 'High Risk', selected: false },
  ]
  selectedItems: any[] = [];
  hubidData: any[];
  @Input() timelineId;

  data: any[] = [];
  currentPage = 1;
  itemsPerPage = 10;
  pagesArray: number[] = [];
  sortColumn: any;
  sortOrder: any;
  filterColumn: any;
  filterKey: any;
  selectedDataStatusCheckboxes: any[] = [];
  selectedRiskStatusCheckboxes: any[] = [];
  paginatedData: any;
  filtersApplied: boolean = false;
  filterParams: any = {};
  sortParams: any = {};
  totalRows: any;
  isDialogOpen: boolean = true;
  storedCheckboxState: any;
  graphItemDisplay: any;
  formattingTime: any;
  previousFilterParams: any = {};
  previousSortParams: any = {};
  displayTimelineGraph: any;
  dataLoader: boolean = true;
  isHovered = false;
  isArrowClicked: boolean = false;
  routerStateSnapshot: any;
  dataFilters: any;
  riskFilters: any;
  hubIdHighlighted: 'asc' | 'desc' | null = null;
  wardHighlighted: 'asc' | 'desc' | null = null;
  BedHighlighted: 'asc' | 'desc' | null = null;
  dataHighlighted: 'asc' | 'desc' | null = null;
  riskHighlighted: 'asc' | 'desc' | null = null;
  isDataRestored: boolean = false;
  idleSubscription: Subscription;
  autoTime: any = 5;
  selectUnpiar:any;
  unPairHub_id:any[] = [];

  constructor(private router: Router,
    public datepipe: DatePipe,
    public activeRoute: ActivatedRoute,
    public auth: AuthService,
    public dialog: MatDialog,
    private patientService: PatientService,
    private baseService: BaseService,
    private idleService: IdleStateService) {
    this.routerStateSnapshot = this.router.routerState.snapshot;
    this.dataToDisplay = []
  }

  ngOnInit(): void {
    window.addEventListener('storage', (e) => {
      if (e.key === 'base_org') {
        if (!this.router.url.includes(e.newValue)) {
          window.location.href = `/${e.newValue}/dashboard`;
        }
      }
    })
    let existDomain = window.location.href.split("/")[3];
    let baseDoamin = localStorage.getItem('base_org');

    if (existDomain != baseDoamin) {
      this.router.navigateByUrl('/login')
    }
    
    this.displaydashboardData();
    this.patientService.logoutEvent.subscribe(() => {
      this.isDropdownOpen = false;
    });
  }
  handlePageHover(isHovered: boolean) {
    this.isHovered = isHovered;
  }
  handleArrowClick() {
    this.isArrowClicked = !this.isArrowClicked;
  }

  restoreCheckboxStates(): void {
    if (this.auth.isTokenValid()) {
      this.storedCheckboxState = JSON.parse(localStorage.getItem('checkboxState')) || {};
      this.displayTimelineGraph = JSON.parse(localStorage.getItem('visualizeClickedObj')) || {};
      let storedDataFilters = JSON.parse(localStorage.getItem('selectedDataFilters')) || {};
      let storedRiskFilters = JSON.parse(localStorage.getItem('selectedRiskFilters')) || {};
      let storedSortingParams = JSON.parse(localStorage.getItem('sortingData')) || {}

      this.data = this.data.map(item => {
        item.selected = this.storedCheckboxState[item.patient_number] || false;
        if (item.selected && this.displayTimelineGraph[item.patient_number] && this.displayTimelineGraph[item.patient_number].isSelected) {
          this.displayGraph();
        }
        return item;
      });
      if (this.isDataRestored) {
        return;
      }
      this.dataStatus = this.dataStatus.map(item => {
        item.selected = storedDataFilters[item.name] || false;
        return item;
      });

      this.riskStatus = this.riskStatus.map(item => {
        item.selected = storedRiskFilters[item.name] || false;
        return item;
      });
      this.selectedDataStatusCheckboxes = this.dataStatus.filter(item => item.selected);
      this.selectedRiskStatusCheckboxes = this.riskStatus.filter(item => item.selected);

      if (this.selectedDataStatusCheckboxes.length > 0) {
        this.filterData('datastatus', this.selectedDataStatusCheckboxes);
        this.selectedDataCheckboxes = this.selectedDataStatusCheckboxes.length;
      }
      if (this.selectedRiskStatusCheckboxes.length > 0) {
        this.filterData('risk_type', this.selectedRiskStatusCheckboxes);
        this.selectedRiskCheckboxes = this.selectedRiskStatusCheckboxes.length;
      }
      this.isDataRestored = true;
      if (Object.keys(storedSortingParams).length > 0) {
        const { sortcolumn, sortorder } = storedSortingParams;

        this.sortingData(sortcolumn, sortorder);
      }
    } else {
      this.auth.refreshToken();
    }

  }

  setupAutomaticRefresh() {
    if (this.auth.isTokenValid()) {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      // Set up a periodic timer to refresh data every 5 minutes (300,000 milliseconds).
      let refreshTimer = JSON.parse(localStorage.getItem('autoRefresh'));
      if (refreshTimer) {
        this.dataSubscription = interval(refreshTimer * 60 * 1000).subscribe(() => {
          this.displaydashboardData();
        });
      } else {
        this.dataSubscription = interval(this.autoTime * 60 * 1000).subscribe(() => {
          this.displaydashboardData();
        });
      }
    } else {
      this.auth.refreshToken();
    }
  }
  onCheckboxChange(item: any) {
    if (this.auth.isTokenValid) {
      if (!item.selected) {
        item.isSelected = false;
        item.timelineGraphData = null;
        this.displayTimeline = false;

        this.storedCheckboxState = JSON.parse(localStorage.getItem('checkboxState')) || {};
        delete this.storedCheckboxState[item.patient_number];
        localStorage.setItem('checkboxState', JSON.stringify(this.storedCheckboxState));
        const visualizeClickedObj = JSON.parse(localStorage.getItem('visualizeClickedObj')) || {};
        if (visualizeClickedObj.hasOwnProperty(item.patient_number)) {
          delete visualizeClickedObj[item.patient_number];
          localStorage.setItem('visualizeClickedObj', JSON.stringify(visualizeClickedObj));
        }
        this.data.forEach((dataItem) => {
          if (dataItem.selected && dataItem.timelineGraphData) {
            this.displayGraph();
          }
        });
      } else {
        // Update the checkbox state in localStorage
        this.storedCheckboxState = JSON.parse(localStorage.getItem('checkboxState')) || {};
        this.storedCheckboxState[item.patient_number] = true;
        localStorage.setItem('checkboxState', JSON.stringify(this.storedCheckboxState));
      }
    } else {
      this.auth.refreshToken();
    }
  }

  PairingDevice(): void {
    if (this.auth.isTokenValid) {
      //this.router.navigateByUrl('connector');
      window.location.href = 'connector';
      localStorage.removeItem('hub_id');
      localStorage.removeItem('hub_description');
    } else {
      this.auth.refreshToken();
    }
  }

  displaydashboardData(): void {
    if (this.auth.isTokenValid()) {
      const params = this.buildParamsWithoutSorting();
      this.dataLoader = true;
      this.patientService.listOfPatients(params).subscribe((res) => {
        if (res === null) {
          this.patientService.setversionData('');
          this.dataLoader = false;
          return;
        }
        this.patientService.setversionData(res.Version);
        this.data = res.data.map((item) => {
          return { ...item, isSelected: false }
        });
        this.totalRows = res.totalRows
        this.updatedTime = res.TimeZone;
        this.updatePagesArray();
        this.setupAutomaticRefresh();
        this.dataLoader = false;
      })
    } else {
      this.auth.refreshToken();
    }
  }

  RefreshData(): void {
    if (this.auth.isTokenValid()) {
      this.displaydashboardData();
      this.setupAutomaticRefresh();
      this.updatedTime = this.updatedTime;
    } else {
      this.auth.refreshToken();
    }
  }
  sortingData(columnName: any, direction: 'asc' | 'desc') {
    if (this.auth.isTokenValid()) {
      if (columnName === this.sortColumn && direction === this.sortOrder) {
        let storedSortingParams = JSON.parse(localStorage.getItem('sortingData')) || {}
        if (storedSortingParams) {
          this.sortParams = {};
        }
        this.sortColumn = null,
          this.sortOrder = null
        localStorage.setItem('sortingData', JSON.stringify(this.sortParams));
        this.displaydashboardData();
        if (direction === 'asc') {
          this.removeBackgroundColor('asc');
        } else {
          this.removeBackgroundColor('desc');
        }
      } else {
        if (columnName === 'hub_id') {
          this.hubIdHighlighted = direction;
          this.wardHighlighted = null;
          this.BedHighlighted = null;
        } else if (columnName === 'ward') {
          this.wardHighlighted = direction;
          this.hubIdHighlighted = null;
          this.BedHighlighted = null;
        } else if (columnName === 'bed') {
          this.BedHighlighted = direction;
          this.hubIdHighlighted = null;
          this.wardHighlighted = null;
        } else if (columnName === 'datastatus') {
          this.dataHighlighted = direction;
          this.hubIdHighlighted = null;
          this.wardHighlighted = null;
          this.BedHighlighted = null;
          this.riskHighlighted = null;
        } else if (columnName === 'risk_type') {
          this.riskHighlighted = direction;
          this.hubIdHighlighted = null;
          this.wardHighlighted = null;
          this.BedHighlighted = null;
          this.dataHighlighted = null;
        }
        this.sortParams = JSON.parse(localStorage.getItem('sortingData'));
        this.sortParams = {
          sortcolumn: columnName,
          sortorder: direction
        }
        localStorage.setItem('sortingData', JSON.stringify(this.sortParams));
        this.sortColumn = columnName;
        this.sortOrder = direction;
        //Check if filter parameters is present or not, and if yes, call combined API
        if (this.previousFilterParams.filtercolumn && this.previousFilterParams.filterkey) {
          this.sendFilterAndSortParamsToApi();
        } else {
          const params = this.buildParamsWithSorting();
          this.previousSortParams = {
            sortcolumn: columnName,
            sortorder: direction
          };
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.restoreCheckboxStates();
          })
        }
      }
    } else {
      this.auth.refreshToken();
    }
  }
  removeBackgroundColor(direction: 'asc' | 'desc') {
    if (direction === 'asc') {
      this.hubIdHighlighted = null;
      this.wardHighlighted = null;
      this.BedHighlighted = null;
      this.dataHighlighted = null;
      this.riskHighlighted = null;
    } else {
      this.hubIdHighlighted = null;
      this.wardHighlighted = null;
      this.BedHighlighted = null;
      this.dataHighlighted = null;
      this.riskHighlighted = null;
    }
  }

  buildParamsWithoutSorting() {
    if (this.auth.isTokenValid()) {
      return new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString());
    } else {
      this.auth.refreshToken();
    }
  }

  buildParamsWithSorting() {
    if (this.auth.isTokenValid()) {
      return new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString())
        .set('sortColumn', this.sortColumn)
        .set('sortOrder', this.sortOrder);
    } else {
      this.auth.refreshToken();
    }
  }

  unpairPatients(): void {
    if (this.auth.isTokenValid() && this.isDialogOpen) {
      this.isDialogOpen = true;
      this.isDropdownOpen = false;
      this.patientService.listofhubids().subscribe((res) => {
        if (this.dialog.openDialogs.length > 0) {
          this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close();
        }
        this.isDialogOpen = true;
        if (res.status == 'success') {
          let hubidDataArray = res.hub_ids.map(obj => {
            let [key, value] = Object.entries(obj)[0];
            return `${key} - ${value}`;
          });
          this.hubidData = hubidDataArray;
        }
        let dialogRef: any;
        dialogRef = this.dialog.open(PatientDataboxListComponent, {
          width: '400px',
          minHeight: 'calc(100vh - 80vh)',
          maxHeight:'318px',
          height : 'auto',
          data: {
            hubIds: this.hubidData,
            yesBtn: 'Unpair',
            cancelBtn: 'Cancel',
          },
        });
        const dialogSubscriber = dialogRef?.afterClosed().subscribe((result) => {
          if (result === 'yes') {
            this.confirmationDialog();
          }
        });
      })
      this.isDialogOpen = false;
    } else {
      this.auth.refreshToken();
    }
  }

  confirmationDialog() {
    if (this.auth.isTokenValid()) {
      this.patientService.selectedHubIds.subscribe(selectedUnpair => {
        this.selectUnpiar = selectedUnpair.hub_ids;
      });
      let dialogRef: any;
      dialogRef = this.dialog.open(DialogComponent, {
        width: '477px',
        minHeight: 'calc(100vh - 80vh)',
        maxHeight:'318px',
        height : 'auto',
        data: {
          type: 'alert',
          headerImg: 'assets/images/closeIcon.png',
          buttonsAlign: 'horizantal',
          label: `<h4>Unpairing devices will stop them from sending data.</h4>
            <h4>Please confirm you want to Unpair the following devices:</h4>
            <b>${this.selectUnpiar.join('<br><br>')}</b>`,
          yesBtn: 'Confirm',
          cancelBtn: 'Cancel',
        },
      });
      const dialogSubscriber = dialogRef?.afterClosed().subscribe((result) => {
        if (result === 'yes') {
          this.patientService.selectedHubIds.subscribe(selectedCheckboxes => {
            this.hubidselection = selectedCheckboxes.hub_ids;
            this.unPairHub_id = this.hubidselection.map(result => result.substring(0, 4).trim());
          });
          let hub_ids = { hub_ids:this.unPairHub_id };
          this.patientService.unpairPatients(hub_ids).subscribe((res) => {
            this.displaydashboardData();
          })
        }
      });
    } else {
      this.auth.refreshToken();
    }
  }
  dataStatus_dropdown(dropdownName: any): void {
    if (this.auth.isTokenValid()) {
      if (this.activeDropdown === dropdownName) {
        this.isDropdownOpen = !this.isDropdownOpen;
      } else {
        this.isDropdownOpen = true;
        this.activeDropdown = dropdownName;
      }
    } else {
      this.auth.refreshToken();
    }
  }

  handleCheckboxSelection(column: string, item: any) {
    if (this.auth.isTokenValid()) {
      if (column === 'datastatus') {
        if (item.selected) {
          this.selectedDataStatusCheckboxes.push(item);
          this.dataFilters = JSON.parse(localStorage.getItem('selectedDataFilters')) || {};
          this.dataFilters[item.name] = true;
        } else {
          this.dataFilters = JSON.parse(localStorage.getItem('selectedDataFilters')) || {};
          this.selectedDataStatusCheckboxes = this.selectedDataStatusCheckboxes.filter(checkbox => checkbox.name !== item.name);
          delete this.dataFilters[item.name];
        }
        localStorage.setItem('selectedDataFilters', JSON.stringify(this.dataFilters));
      } else if (column === 'risk_type') {
        if (item.selected) {
          this.selectedRiskStatusCheckboxes.push(item);
          this.riskFilters = JSON.parse(localStorage.getItem('selectedRiskFilters')) || {};
          this.riskFilters[item.name] = true;
        } else {
          this.riskFilters = JSON.parse(localStorage.getItem('selectedRiskFilters')) || {};
          this.selectedRiskStatusCheckboxes = this.selectedRiskStatusCheckboxes.filter(checkbox => checkbox.name !== item.name);
          delete this.riskFilters[item.name];
        }
        localStorage.setItem('selectedRiskFilters', JSON.stringify(this.riskFilters));
      }
    } else {
      this.auth.refreshToken();
    }
  }
  filterData(column: string, selectedCheckboxes: any[]) {
    if (this.auth.isTokenValid()) {
      this.selectedDataCheckboxes = this.selectedDataStatusCheckboxes.length;
      this.selectedRiskCheckboxes = this.selectedRiskStatusCheckboxes.length;
      let otherColumn: any;
      if (column === 'datastatus') {
        selectedCheckboxes = this.selectedDataStatusCheckboxes;
        otherColumn = 'risk_type';
      } else if (column === 'risk_type') {
        selectedCheckboxes = this.selectedRiskStatusCheckboxes;
        otherColumn = 'datastatus';
      }
      const selectedCheckboxValues = selectedCheckboxes.map(item => item.name).join(',');
      this.filterColumn = column;
      this.filterKey = selectedCheckboxValues;
      this.filterParams = {
        filtercolumn: column,
        filterkey: selectedCheckboxValues
      }
      const params = this.buildParamsWithFiltering();
      this.previousFilterParams = {
        filtercolumn: this.filterParams.filtercolumn,
        filterkey: this.filterParams.filterkey
      };
      this.filtersApplied = true;
      this.patientService.listOfPatients(params).subscribe((res) => {
        this.data = res.data;
        this.totalRows = res.totalRows;
        this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
        this.currentPage = 1;
        this.updatePagesArray();
        this.restoreCheckboxStates();

        if (selectedCheckboxes.length === 0) {
          this.filterColumn = null;
          this.filterKey = null;
          this.filterParams = null;
          this.filtersApplied = false;
          if (this.selectedCheckboxesForColumn(otherColumn).length > 0) {
            this.filterData(otherColumn, selectedCheckboxes);
          } else {
            this.displaydashboardData();
          }

          if (column === 'datastatus') {
            this.isDropdownOpen = false;
          } else if (column === 'risk_type') {
            this.isDropdownOpen = false;
          }
        }
      });
      this.isDropdownOpen = false;
      if (this.selectedDataStatusCheckboxes.length == 0 && this.selectedRiskStatusCheckboxes.length == 0) {
        this.isDropdownOpen = false;
        this.displaydashboardData();
      }
    } else {
      this.auth.refreshToken();
    }
  }

  selectedCheckboxesForColumn(column: string): any[] {
    if (column === 'datastatus') {
      return this.selectedDataStatusCheckboxes;
    } else if (column === 'risk_type') {
      return this.selectedRiskStatusCheckboxes;
    }

    return [];
  }

  buildParamsWithFiltering() {
    if (this.auth.isTokenValid()) {
      return new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString())
        .set('filterColumn', this.filterColumn)
        .set('filterKey', this.filterKey);
    } else {
      this.auth.refreshToken();
    }
  }

  buildSortParamsWithFilter() {
    if (this.auth.isTokenValid()) {
      let params = new HttpParams()
        .set('pageNumber', this.currentPage.toString())
        .set('pageSize', this.itemsPerPage.toString());

      if (this.filterParams.filtercolumn && this.filterParams.filterkey) {
        params = params
          .set('filterColumn', this.filterParams.filtercolumn)
          .set('filterKey', this.filterParams.filterkey);

        this.previousFilterParams = { ...this.filterParams };
      } else if (this.previousFilterParams.filtercolumn && this.previousFilterParams.filterkey) {
        params = params
          .set('filterColumn', this.previousFilterParams.filtercolumn)
          .set('filterKey', this.previousFilterParams.filterkey);
      }

      if (this.sortParams.sortcolumn && this.sortParams.sortorder) {
        params = params
          .set('sortColumn', this.sortParams.sortcolumn)
          .set('sortOrder', this.sortParams.sortorder);

        this.previousSortParams = { ...this.sortParams };
      }
      return params;
    }
  }

  sendFilterAndSortParamsToApi() {
    if (this.auth.isTokenValid()) {
      let params = this.buildSortParamsWithFilter();
      if (this.filterParams.filtercolumn && this.filterParams.filterkey) {
        this.patientService.listOfPatients(params).subscribe((res) => {
          this.data = res.data;
          this.totalRows = res.totalRows;
          this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
          this.restoreCheckboxStates();
        });
      }
    } else {
      this.auth.refreshToken();
    }
  }
  closeFilter() {
    if (this.auth.isTokenValid()) {
      this.isDropdownOpen = false;
    } else {
      this.auth.refreshToken(this.routerStateSnapshot.url);
    }
  }
  displayGraph() {
    if (this.auth.isTokenValid()) {
      this.displayTimeline = true;
      this.selectedItems = this.data.filter(item => {
        if (item.selected) {
          item.isSelected = true;
          const visualizeClickedObj = JSON.parse(localStorage.getItem('visualizeClickedObj')) || {};
          visualizeClickedObj[item.patient_number] = {
            isSelected: item.isSelected,
          };
          localStorage.setItem('visualizeClickedObj', JSON.stringify(visualizeClickedObj));
        }
        return item.selected;
      });
      const selectedValue: any = [] = this.selectedItems.map((item) => item.patient_number).join(',');
      const params = new HttpParams()
        .set('patient_numbers', selectedValue)
      this.patientService.graphData(params).subscribe((res) => {
        this.data = this.data.map((item) => {
          let timelineGraphData = res[item?.patient_number];
          if (timelineGraphData) {
            item.timelineGraphData = timelineGraphData;
            let timeGraph = timelineGraphData.Timezone;
            this.formattingTime = this.formatData(item.patient_number.split('-')[1], timeGraph);
            item.timeGraphId = this.formattingTime;
          }
          return item;
        });
      })
    } else {
      this.auth.refreshToken();
    }
  }

  formatData(patient, timezone) {
    let patientBed = patient.substring(0, 3)
    let patientValue = patient.substring(3)
    let [, datePart, timePart, timeZoneAbbreviation] = timezone.match(/(\d{4}-\d{2}-\d{2}), (\d{2}:\d{2}:\d{2}) (\w+)/) || [];

    // Format the result
    let convertFormat = `${patientBed} ${patientValue}: ${timeZoneAbbreviation} ${datePart}_${timePart}`;
    return convertFormat;
  }

  closeAllgraphs() {
    if (this.auth.isTokenValid()) {
      this.displayTimeline = false;
      this.data = this.data.map(item => {
        item.timelineGraphData = null;
        item.selected = false;
        item.isSelected = false;
        this.storedCheckboxState = JSON.parse(localStorage.getItem('checkboxState')) || {};
        delete this.storedCheckboxState[item.patient_number];
        localStorage.setItem('checkboxState', JSON.stringify(this.storedCheckboxState));
        const visualizeClickedObj = JSON.parse(localStorage.getItem('visualizeClickedObj')) || {};
        delete visualizeClickedObj[item.patient_number];
        localStorage.setItem('visualizeClickedObj', JSON.stringify(visualizeClickedObj));
        return item;
      });
    } else {
      this.auth.refreshToken();
    }
  }


  previousPage() {
    if (this.auth.isTokenValid()) {
      if (this.currentPage > 1) {
        this.goToPage(this.currentPage - 1);
        if (this.sortColumn && this.sortOrder && this.filterColumn && this.filterKey) {
          const params = this.sortColumn && this.sortOrder && this.filterColumn && this.filterKey
            ? this.buildSortParamsWithFilter()
            : this.buildParamsWithoutSorting();
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.totalRows = res.totalRows;
            this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
            this.restoreCheckboxStates();
          });
        } else if (this.sortColumn && this.sortOrder) {
          const params = this.sortColumn && this.sortOrder ? this.buildParamsWithSorting() : this.buildParamsWithoutSorting();
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.restoreCheckboxStates();
          });
        } else if (this.filterColumn && this.filterKey) {
          const params = this.filterColumn && this.filterKey ? this.buildParamsWithFiltering() : this.buildParamsWithoutSorting();
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.totalRows = res.totalRows;
            this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
            this.restoreCheckboxStates();
          });
        } else {
          this.restoreCheckboxStates();
        }
      }
    } else {
      this.auth.refreshToken();
    }
  }

  nextPage() {
    if (this.auth.isTokenValid()) {
      if (this.currentPage < this.totalPages) {
        this.goToPage(this.currentPage + 1);
        if (this.sortColumn && this.sortOrder && this.filterColumn && this.filterKey) {
          const params = this.sortColumn && this.sortOrder && this.filterColumn && this.filterKey
            ? this.buildSortParamsWithFilter()
            : this.buildParamsWithoutSorting();
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.totalRows = res.totalRows;
            this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
            this.restoreCheckboxStates();
          });
        } else if (this.sortColumn && this.sortOrder) {
          const params = this.sortColumn && this.sortOrder ? this.buildParamsWithSorting() : this.buildParamsWithoutSorting();
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.restoreCheckboxStates();
          });
        } else if (this.filterColumn && this.filterKey) {
          const params = this.filterColumn && this.filterKey ? this.buildParamsWithFiltering() : this.buildParamsWithoutSorting();
          this.patientService.listOfPatients(params).subscribe((res) => {
            this.data = res.data;
            this.totalRows = res.totalRows;
            this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
            this.restoreCheckboxStates();
          });
        } else {
          this.restoreCheckboxStates();
        }
      }
    } else {
      this.auth.refreshToken();
    }
  }

  get totalPages() {
    if (this.auth.isTokenValid()) {
      return Math.ceil(this.totalRows / this.itemsPerPage);
    } else {
      this.auth.refreshToken();
    }
  }

  updatePagesArray() {
    if (this.auth.isTokenValid()) {
      this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
      if (this.sortColumn && this.sortOrder && this.filterColumn && this.filterKey) {
        const params = this.sortColumn && this.sortOrder && this.filterColumn && this.filterKey
          ? this.buildSortParamsWithFilter()
          : this.buildParamsWithoutSorting();
        this.patientService.listOfPatients(params).subscribe((res) => {
          this.data = res.data;
          this.totalRows = res.totalRows;
          this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
          this.restoreCheckboxStates();
        });
      } else if (this.sortColumn && this.sortOrder) {
        const params = this.sortColumn && this.sortOrder ? this.buildParamsWithSorting() : this.buildParamsWithoutSorting();
        this.patientService.listOfPatients(params).subscribe((res) => {
          this.data = res.data;
          this.restoreCheckboxStates();
        });
      } else if (this.filterColumn && this.filterKey) {
        const params = this.filterColumn && this.filterKey ? this.buildParamsWithFiltering() : this.buildParamsWithoutSorting();
        this.patientService.listOfPatients(params).subscribe((res) => {
          this.data = res.data;
          this.totalRows = res.totalRows;
          this.pagesArray = new Array(Math.ceil(this.totalRows / this.itemsPerPage)).fill(0).map((_, index) => index + 1);
          this.restoreCheckboxStates();
        });
      } else {
        this.restoreCheckboxStates();
      }
    } else {
      this.auth.refreshToken();
    }
  }

  goToPage(page: number) {
    if (this.auth.isTokenValid()) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        this.displaydashboardData();
      }
    } else {
      this.auth.refreshToken();
    }
  }
  getColor(riskStatus: string): string {
    if (riskStatus === 'Mid Risk') {
      return 'yellow';
    } else if (riskStatus === 'High Risk') {
      return 'red';
    } else if (riskStatus === 'No Risk') {
      return 'green';
    } else if (riskStatus === 'Out Of Bed') {
      return 'grey';
    } else if (riskStatus === 'Check Sensor Cable') {
      return 'light_red';
    }
  }
  ngOnDestroy() {
    if (this.auth.isTokenValid()) {
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
    } else {
      this.auth.refreshToken();
    }
  }
}